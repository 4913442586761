import { NgModule } from '@angular/core';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import {
	ApolloClientOptions,
	ApolloLink,
	InMemoryCache,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';

import { environment } from '../environments/environment';

// Contentful GraphQL endpoint
const uri = `https://graphql.contentful.com/content/v1/spaces/${environment.contentful_config.space}`;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
	// Set authentication header
	const auth = setContext((operation, context) => {
		return {
			headers: {
				Authorization: `Bearer ${environment.contentful_config.accessToken}`,
			},
		};
	});

	return {
		link: ApolloLink.from([auth, httpLink.create({ uri })]),
		cache: new InMemoryCache(),
	};
}

@NgModule({
	imports: [
		ApolloModule
	],
	providers: [{
		provide: APOLLO_OPTIONS,
		useFactory: createApollo,
		deps: [HttpLink],
	}],
})
export class GraphQLModule { }

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	contentful_config: {
		space: 'nkl33qlmu983',
		accessToken: 'DdkUUN_tDFNIGE7m2hPo-SjCyMUX3VzCgtScNyR8AJI',
		MANAGEMENT_ACCESS_TOKEN: 'CFPAT-q2MLHBGUFVVACtwZiD9mcsQxqiXr3N4COVpgAdb3OZA',
		contentTypeIds: {
			aboutPage: 'aboutPage',
			termsAndConditionsPage: 'termsAndConditionsPage',
			termsOfUsePage: 'termsOfUsePage',
			privacyPolicyPage: 'privacyPolicyPage',
			contactPage: 'contactPage',
			homePage: 'homePage',
			workPage: 'workPage',
			newsPage: 'newsPage',
			work: 'work',
			clients: 'clients',
			spot: 'spot'
		},
		// order: 'work.fields.order'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.

import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { sectionsMetadata } from './metadata';

const routes: Routes = [
  { path: 'about', loadComponent: () => import('./pages/about/about.component').then(mod => mod.AboutComponent), data: sectionsMetadata.aboutPage },
  { path: 'privacy-policy', loadComponent: () => import('./pages/privacy-policy/privacy-policy.component').then(mod => mod.PrivacyPolicyComponent), data: sectionsMetadata.PrivacyPolicyPage },
  { path: 'terms-of-use', loadComponent: () => import('./pages/terms-of-use/terms-of-use.component').then(mod => mod.TermsOfUseComponent), data: sectionsMetadata.TermsOfUsePage },
  {
    path: 'news', loadComponent: () => import('./pages/news/news.component').then(mod => mod.NewsComponent),
    data: sectionsMetadata.newsPage,
    children: [
      {
        path: 'work',
        loadComponent: () => import('./pages/news/work/work.component').then(mod => mod.WorkComponent),
        data: sectionsMetadata.newsPageWork
      },
      {
        path: 'people',
        loadComponent: () => import('./pages/news/people/people.component').then(mod => mod.PeopleComponent),
        data: sectionsMetadata.newsPagePeople
      },
      {
        path: 'perspectives',
        loadComponent: () => import('./pages/news/perspectives/perspectives.component').then(mod => mod.PerspectivesComponent),
        data: sectionsMetadata.newsPagePerspectives
      },
      {
        path: 'awards',
        loadComponent: () => import('./pages/news/awards/awards.component').then(mod => mod.AwardsComponent),
        data: sectionsMetadata.newsPageAwards
      },
    ]
  },
  { path: 'news/:id', loadComponent: () => import('./pages/news-item/news-item.component').then(mod => mod.NewsItemComponent), data: sectionsMetadata.newsItemPage },
  { path: 'contact', loadComponent: () => import('./pages/contact/contact.component').then(mod => mod.ContactComponent), data: sectionsMetadata.contactPage },
  { path: 'postrequest', loadComponent: () => import('./pages/post-request/post-request.component').then(mod => mod.PostRequestComponent), data: sectionsMetadata.postrequestPage },
  { path: '', loadComponent: () => import('./pages/home/home.component').then(mod => mod.HomeComponent), data: sectionsMetadata.homePage },
  {
    path: 'work',
    loadComponent: () => import('./pages/projects/projects.component').then(mod => mod.ProjectsComponent),
    data: sectionsMetadata.workPage,
    children: [
      { path: 'all', loadComponent: () => import('./pages/projects/all/all.component').then(mod => mod.AllComponent) },
      { path: 'production', loadComponent: () => import('./pages/projects/production/production.component').then(mod => mod.ProductionComponent) },
      { path: 'post', loadComponent: () => import('./pages/projects/post/post.component').then(mod => mod.PostComponent) },
      { path: 'design-animation', loadComponent: () => import('./pages/projects/design-animation/design-animation.component').then(mod => mod.DesignAnimationComponent) },
      { path: 'interactive', loadComponent: () => import('./pages/projects/interactive/interactive.component').then(mod => mod.InteractiveComponent) }
    ]
  },
  { path: 'work/campaign/:slug/:id', loadComponent: () => import('./pages/project/project.component').then(mod => mod.ProjectComponent), data: { stage: 'workItem' } },
  { path: '**', component: PageNotFoundComponent }
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'top',
    // onSameUrlNavigation: 'reload'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

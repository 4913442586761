import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Event } from '@angular/router';
import * as WebFont from 'webfontloader';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import gsap from 'gsap';
import ScrollSmoother from 'gsap/ScrollSmoother';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }]
})
export class AppComponent implements OnInit {
  smoother;
  isNewSession: boolean;
  public isIntroDone = false;
  sawIntroAnimation = false;
  isDone = false;

  location: Location;
  animationType = '';
  modules = ['about', 'contact', 'home', 'work'];
  state: string;
  pageIndex = 0;
  animating: boolean;
  title = 'zmbz-fin';
  currentAnimation?: gsap.core.Timeline;
  private classes: Array<string> = [];
  isCategoryPage = false;
  categories = [
    'all',
    'production',
    'post',
    'design-animation',
    'interactive'
  ];

  removeBodyClasses() {
    this.classes.map((key: string) => {
      if (key.length > 0) {
        document.body.classList.remove(key + '-page');
      }
    });
  }

  addBodyClasses(path: any) {
    this.classes = path.replace(/\/[0-9]+/g, '').split('?')[0].split('/') && path.replace(/\/[0-9]+/g, '').split('?')[0].split('#');
    this.classes.map((key: string) => {

      if (key.length > 0) {
        document.body.classList.add(key + '-page');
      }

      if (key.length === 0) {
        document.body.classList.add('home-page');
      } else {
        document.body.classList.remove('home-page');
      }

      if (this.router.url.replace(/\/[0-9]+/g, '').split('?')[0].split('/')[2] === 'campaign') {
        document.body.classList.add('work-item-page');
      } else {
        document.body.classList.remove('work-item-page');
      }

      if (this.router.url.replace(/\/[0-9]+/g, '').split('?')[0].split('/')[1] === 'work') {
        document.body.classList.add('work-page');
      } else {
        document.body.classList.remove('work-page');
      }

    });
  }

  constructor(private router: Router,
    private deviceService: DeviceDetectorService,
    location: Location) {
    this.location = location;
    const isCategoryPage =
      location.isCurrentPathEqualTo('/work/' + this.categories[0]) ||
      location.isCurrentPathEqualTo('/work/' + this.categories[1]) ||
      location.isCurrentPathEqualTo('/work/' + this.categories[2]) ||
      location.isCurrentPathEqualTo('/work/' + this.categories[3]) ||
      location.isCurrentPathEqualTo('/work/' + this.categories[4]);

    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError) {
        this.removeBodyClasses();
        this.addBodyClasses(event.url.substring(1));
        gsap.set('.app-wrapper', { autoAlpha: 0 });
      }
      if (event instanceof NavigationStart || event instanceof NavigationEnd && !isCategoryPage) {
        gsap.set('.page-wipe-white svg', { scale: 1, opacity: 0 });
        this.leaveAnimation();
        gsap.set('.app-wrapper', { autoAlpha: 0 });
      }
      if (event instanceof NavigationEnd) {
        // setTimeout(() => this.enterAnimation(), 500);
      }

    });
  }

  get device(): any {
    return this.deviceService.getDeviceInfo();
  }
  get isDesktop(): boolean {
    return this.deviceService.isDesktop();
  }

  ngAfterViewInit() {
    gsap.registerPlugin(ScrollSmoother, ScrollTrigger);
    if (this.isDesktop && this.device.browser === 'Chrome') {
      this.smoother = ScrollSmoother.create({
        wrapper: 'app-root',
        content: '#smooth-content',
        smooth: 1,
        // normalizeScroll: true
      });
      ScrollTrigger.addEventListener('refresh', () => {
        //
        // when the page refreshes
        //
      });
      setTimeout(() => {
        // this.smoother.effects('[data-speed], [data-lag]');
        ScrollTrigger.refresh();
      }, 50);
    }
  }

  ngOnInit() {
    gsap.set('.app-wrapper', { autoAlpha: 0 });
    WebFont.load({
      custom: {
        families: ['F37 Lineca', 'IBM Plex Sans Roman']
      }
    });
    setTimeout(() => {
      this.checkCookie();
    }, 100);

  }

  setInitialState() {
    gsap.to(['.page-wipe-white', '.page-wipe-blue'], {
      duration: 1.5,
      height: '0',
      ease: 'power4.inOut',
      stagger: .08
    });
  }

  onRouteUpdate() {
    if (this.currentAnimation?.reversed()) {
      this.currentAnimation.play();
      return;
    }
    this.currentAnimation = this.scaleImgAnimation();
    this.done();
  }

  scaleImgAnimation() {
    const imgTimeline = gsap.timeline();

    imgTimeline.set('.page-wipe-white svg', { scale: 1, opacity: 0 })
      .set('feImage', { attr: { x: 0, y: 0, width: "70%", height: "70%" } })
      .set("#displacement-map", { attr: { scale: 100 } })
      .to('.page-wipe-white svg', { duration: 0.2, opacity: 1, ease: 'none' })
      .to('.page-wipe-white svg', { duration: 3, scale: 1.5, ease: 'none' }, '<')
      .to("#displacement-map", { duration: 3, attr: { scale: 0 } }, '<')
      .to("feImage", { duration: 3, attr: { x: -102, y: -62, width: "250%", height: "250%" } }, '<')
      .add(() => this.done(), '>+=1');

    let xlink = "http://www.w3.org/1999/xlink";
    let imgUrl = "../../assets/images/ripple142.png";
    let feImage = document.querySelector("feImage");

    toBase64(imgUrl, function (data) {

      feImage.setAttributeNS(xlink, "xlink:href", data);
    });


    function toBase64(url, callback) {

      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = function () {
        let canvas = document.createElement("canvas") as HTMLCanvasElement;
        let ctx = canvas.getContext("2d");
        canvas.height = 142;
        canvas.width = 142;
        ctx.drawImage(img, 0, 0);

        let dataURL = canvas.toDataURL("image/png");
        callback(dataURL);
        canvas = null;
      };

      img.src = url;
    }
    return imgTimeline;
  }

  leaveAnimation() {
    const timeline = gsap.timeline({ defaults: { stagger: 0.03, duration: 0.9, ease: 'expo.inOut' } }),
      loaderPanels = gsap.utils.toArray('.loader__panel');
    gsap.set('.app-wrapper', { autoAlpha: 0 });
    timeline.fromTo(loaderPanels, {
      clipPath: 'inset(100% 0% 0% 0%)',
      webkitClipPath: 'inset(100% 0% 0% 0%)'
    }, {
      duration: 1,
      clipPath: 'inset(0% 0% 0% 0%)',
      webkitClipPath: 'inset(0% 0% 0% 0%)'
    })
      .fromTo(loaderPanels, {
        clipPath: 'inset(0% 0% 0% 0%)',
        webkitClipPath: 'inset(0% 0% 0% 0%)'
      }, {
        duration: 1,
        clipPath: 'inset(100% 0% 0% 0%)',
        webkitClipPath: 'inset(100% 0% 0% 0%)',
        stagger: { from: 'end', each: 0.03, ease: 'sine.inOut' }
      }, '>')
      .to('.app-wrapper', { duration: 1, autoAlpha: 1, ease: 'power2.inOut' }, '>-=0.6');

    return timeline;
  }

  done() {
    this.isDone = true;
    this.sawIntroAnimation = false;
    document.body.classList.remove('intro-animation');
    sessionStorage.setItem('introAnimation', 'true');
  }

  setCookie(cname, cvalue, exdays) {
    const d = new Date();
    d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
  }

  getCookie(cname) {
    const name = cname + '=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return '';
  }

  checkCookie() {
    const cookied = sessionStorage.getItem('introAnimation');
    if (cookied) {
      this.sawIntroAnimation = false;
      this.isIntroDone = true;
    } else {
      this.sawIntroAnimation = true;
      this.isIntroDone = false;
      this.scaleImgAnimation();
    }
  }


}

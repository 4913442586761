export const sectionsMetadata = {
	aboutPage: {
		meta: {
			title: 'FIN STUDIOS | ABOUT',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/about/'
		}
	},
	newsPage: {
		meta: {
			title: 'FIN STUDIOS | NEWS',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/news/'
		}
	},
	newsPageWork: {
		meta: {
			title: 'FIN STUDIOS | NEWS | WORK',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/news/'
		}
	},
	newsPagePeople: {
		meta: {
			title: 'FIN STUDIOS | NEWS | PEOPLE',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/news/'
		}
	},
	newsPagePerspectives: {
		meta: {
			title: 'FIN STUDIOS | NEWS | PERSPECTIVES',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/news/'
		}
	},
	newsPageAwards: {
		meta: {
			title: 'FIN STUDIOS | NEWS | AWARDS',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/news/'
		}
	},
	newsItemPage: {
		meta: {
			title: 'FIN STUDIOS | NEWS',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/news/'
		}
	},
	contactPage: {
		meta: {
			title: 'FIN STUDIOS | CONTACT',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/contact/'
		}
	},
	workPage: {
		meta: {
			title: 'FIN STUDIOS | WORK',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/work/'
		}
	},
	homePage: {
		meta: {
			title: 'FIN STUDIOS',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/'
		}
	},
	postrequestPage: {
		meta: {
			title: 'FIN STUDIOS | POST REQUEST',
			description: 'A full service production studio that creates compelling branded and original content.',
			url: 'https://www.finstudios-la.com/postrequest/'
		}
	},
	PrivacyPolicyPage: {
		meta: {
			title: 'ZAMBEZI | PRIVACY POLICY',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/privacy-policy',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	},
	TermsOfUsePage: {
		meta: {
			title: 'ZAMBEZI | TERMS OF USE',
			description: 'An integrated & independent creative communications agency that is female-owned.',
			url: 'https://www.zmbz.com/terms-of-use',
			img: 'https://images.ctfassets.net/uwpg4vevsh2j/nG7cThJDmmWbWyyxC1VC7/ec825b81c9227b56e6580720fab66fe8/share.jpeg'
		}
	}
};

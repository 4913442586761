import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart, Event, RouterLink } from '@angular/router';
import { Location, LocationStrategy, PathLocationStrategy, NgIf } from '@angular/common';

@Component({
    selector: 'app-site-footer',
    templateUrl: './site-footer.component.html',
    styleUrls: ['./site-footer.component.scss'],
    providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
    standalone: true,
    imports: [NgIf, RouterLink]
})
export class SiteFooterComponent implements OnInit {
  show = false;
  isAboutPage: boolean;
  isWorkPage: boolean;
  isContactPage: boolean;
  isHomePage: boolean;
  currentPage: string;
  isOpen = false;
  location: Location;
  currentYear: number = new Date().getFullYear();

  constructor(location: Location, private router: Router) {
    this.location = location;

    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel || event instanceof NavigationError) {
        const isAbout = location.isCurrentPathEqualTo('/about');
        const isWork = location.isCurrentPathEqualTo('/work');
        const isContact = location.isCurrentPathEqualTo('/contact');
        const isHome = location.isCurrentPathEqualTo('/');

        if (isAbout) {
          this.isAboutPage = true;
          this.currentPage = 'About';
        } else {
          this.isAboutPage = false;
        }
        if (isWork) {
          this.isWorkPage = true;
          this.currentPage = 'Work';
        } else {
          this.isWorkPage = false;
        }
        if (isContact) {
          this.isContactPage = true;
          this.currentPage = 'Contact';
        } else {
          this.isContactPage = false;
        }
        if (isHome) {
          this.isHomePage = true;
          this.currentPage = 'Home';
        } else {
          this.isHomePage = false;
        }
      }
    });
    setTimeout(() => {
      this.show = true;
    }, 1800);
  }

  ngOnInit(): void {

  }

}

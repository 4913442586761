import { Component, OnInit, HostListener, Inject, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationCancel, NavigationError, NavigationStart, Event, RouterLinkActive, RouterLink } from '@angular/router';
import { DOCUMENT, NgClass } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import { gsap } from 'gsap';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
  standalone: true,
  imports: [NgClass, RouterLinkActive, RouterLink]
})
export class NavComponent implements OnInit {
  @ViewChild('hamburger') hamburger: ElementRef<HTMLElement>;
  navIsFixed: boolean;
  scrolling: boolean;
  tl = gsap.timeline({ paused: true, reversed: true });
  isOpen = false;
  color: '#d9ff00';
  attr: any;
  public device: string;

  constructor(private deviceService: DeviceDetectorService,
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private element: ElementRef) {
    router.events.subscribe((event: Event) => {
      document.body.classList.remove('nav--open');
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel || event instanceof NavigationError) {
        if (document.body.classList.contains('about-us-page') ||
          document.body.classList.contains('about-page')) {
          this.navIsFixed = true;
        } else {
          this.navIsFixed = false;
        }
      }
    });
  }

  ngOnInit(): void {
    this.animateMenu();
  }

  animateMenu() {
    let $hamburger = <HTMLInputElement>document.getElementById("hamburger");

    // let $hamburger = this.hamburger.nativeElement;
    const device = this.deviceService.getDeviceInfo();
    const browser = device.browser;
    const mobile = this.deviceService.isMobile();
    const tablet = this.deviceService.isTablet();
    const desktop = this.deviceService.isDesktop();

    if (mobile) {
      document.body.classList.add('is-mobile');
    }

    if (tablet) {
      document.body.classList.add('is-tablet');
    }

    if (desktop) {
      document.body.classList.add('is-desktop');
    }

    gsap.set('.line01', { x: -40 });
    gsap.set('.line02', { x: -20 });
    gsap.set('.line03', { x: 0 });
    gsap.set('.navigation--mobile', { xPercent: -50, yPercent: -50 });
    gsap.set('.navigation--mobile li', { yPercent: -590, skewY: 6 });
    const tl = gsap.timeline();
    if (desktop || tablet) {
      tl.to('.line03', { duration: 0.5, x: '-=110' }, 0)
        .to('.line01', { duration: 0.5, x: '+=130' }, 0)
        .to('.line02', { duration: 0.5, x: 0 }, 0)
        .to('.menu--mobile', { duration: 0.5, right: 0, width: '66vw', autoAlpha: 1 }, 0)
        .to('.navigation--mobile li', {
          duration: 1,
          yPercent: 0,
          skewY: 0,
          stagger: {
            from: "end",
            each: 0.05
          },
          ease: 'expo.out'
        }, 0.5)
        .reverse();
    }
    if (mobile) {
      tl.to('.line03', { duration: 0.5, x: '-=110' }, 0)
        .to('.line01', { duration: 0.5, x: '+=130' }, 0)
        .to('.line02', { duration: 0.5, x: 0 }, 0)
        .to('.menu--mobile', { duration: 0.5, right: 0, width: '100vw', autoAlpha: 1 }, 0)
        .to('.navigation--mobile li', {
          duration: 1,
          yPercent: 0,
          skewY: 0,
          stagger: {
            from: "end",
            each: 0.05
          },
          ease: 'expo.out'
        }, 0.5)
        .reverse();
    }
    $hamburger.onclick = function (e) {
      // hamburger.on('click', function (e) {
      tl.reversed(!tl.reversed());
      document.body.classList.toggle('nav--open');
      $hamburger.classList.toggle('nav--is-open');
    };

    this.router.events.subscribe((event: Event) => {
      tl.reverse();
    });
  }

  @HostListener('window:scroll', [])
  onscroll() {
    const scrollNumber = window.scrollY;

    if (scrollNumber > 100) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && scrollNumber < 10) {
      this.navIsFixed = false;
    }

    if (document.body.classList.contains('home-page') && scrollNumber > 100) {
      gsap.to('#navLogo', { fill: '#0A00CC', ease: 'power2.inOut' });
      gsap.to('.hamburger line', { stroke: '#0A00CC', ease: 'power2.inOut' });
    } else if (document.body.classList.contains('home-page') && scrollNumber < 10) {
      gsap.to('#navLogo', { fill: '#fff', ease: 'power2.inOut' });
      gsap.to('.hamburger line', { stroke: '#fff', ease: 'power2.inOut' });
    }
  }
}

import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError, Event, ChildrenOutletContexts, RouterLink, RouterLinkActive } from '@angular/router';
import { Location, NgIf, NgClass } from '@angular/common';
import { DeviceDetectorService } from 'ngx-device-detector';
import gsap from 'gsap';
import { NavComponent } from '../nav/nav.component';

@Component({
  selector: 'app-site-nav',
  templateUrl: './site-nav.component.html',
  styleUrls: ['./site-nav.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, RouterLink, RouterLinkActive, NavComponent]
})
export class SiteNavComponent implements OnInit {
  @ViewChild('Handle') handleEl: ElementRef<HTMLElement>;
  isMobile;
  isDektop;
  location: Location;
  public screenWidth: any;
  public screenHeight: any;
  currentAnimation?: gsap.core.Timeline;
  navIsFixed: boolean;
  scrolling: boolean;

  constructor(private router: Router,
    location: Location,
    private deviceService: DeviceDetectorService) {
    this.location = location;
    router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart || event instanceof NavigationEnd ||
        event instanceof NavigationCancel ||
        event instanceof NavigationError) {
        this.setInitialState();
      }
    });
  }

  ngOnInit() {
    this.checkWidth();
    setTimeout(() => this.setInitialState(), 100);
  }


  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    this.checkWidth();
  }

  checkWidth() {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;
    if (this.screenWidth <= 768) {
      this.isMobile = true;
    }

    if (this.screenWidth >= 768) {
      this.isDektop = true;
    }
  }

  setInitialState() {
    if (document.body.classList.contains('home-page')) {
      gsap.to('#navLogo', { fill: '#fff', ease: 'power2.inOut' });
    } else {
      gsap.to('#navLogo', { fill: '#0A00CC', ease: 'power2.inOut' });
    }
  }

  onHover() {
    if (this.currentAnimation?.reversed()) {
      this.currentAnimation.play();
      return;
    }
    this.currentAnimation = this.enterAnimation();
  }

  enterAnimation() {
    const timeline = gsap.timeline();
    if (document.body.classList.contains('home-page')) {
      timeline.to('#navLogo', { duration: 1, scale: 1.2, fill: '#0A00CC', ease: 'power2.inOut' });
    } else {
      timeline.to('#navLogo', { duration: 1, scale: 1.2, fill: '#fff', ease: 'power2.inOut' });
    }

    return timeline;
  }

  leaveAnimation() {
    const timeline = gsap.timeline();
    if (document.body.classList.contains('home-page')) {
      timeline.to('#navLogo', { duration: 1, scale: 1, fill: '#fff', ease: 'power2.inOut' });
    } else {
      timeline.to('#navLogo', { duration: 1, scale: 1, fill: '#0A00CC', ease: 'power2.inOut' });
    }

    return timeline;
  }

  onMouseLeave() {
    if (
      !this.currentAnimation.reversed() &&
      this.currentAnimation.progress() < 0.3
    ) {
      this.currentAnimation.reverse();
      return;
    }
    this.currentAnimation.pause();
    this.currentAnimation = this.leaveAnimation();
  }

  @HostListener('window:scroll', [])
  onscroll() {
    const scrollNumber = window.scrollY;

    if (scrollNumber > 100) {
      this.navIsFixed = true;
    } else if (this.navIsFixed && scrollNumber < 10) {
      this.navIsFixed = false;
    }

    if (document.body.classList.contains('home-page') && scrollNumber > 100) {
      gsap.to('#navLogo', { fill: '#0A00CC', ease: 'power2.inOut' });
    } else if (document.body.classList.contains('home-page') && scrollNumber < 10) {
      gsap.to('#navLogo', { fill: '#fff', ease: 'power2.inOut' });
    }
  }

}

<footer class="footer section is-medium" *ngIf="show">
	<div class="container is-fluid">
		<p *ngIf="isHomePage" class="home-footer-links">
			<a class="footer__item" routerLink="/work">See More</a> / <a class="footer__item"
				routerLink="/contact">Get In Touch</a>
		</p>
		<p class="phone-email-links">
			<a href="tel:+13104506800" target="_blank">310.450.6800</a> / <a
				href="mailto:info@finstudios-la.com" target="_blank">info&#64;finstudios-la.com</a>
		</p>
		<p class="address-copy">
			3522 Hayden Ave, Culver City, CA 90232
		</p>
		<p class="legal-links">
			<a class="footer__item" routerLink="/terms-of-use">Terms of Use</a> / <a
				class="footer__item" routerLink="/privacy-policy">Privacy Policy</a>
		</p>
		<p class="legal-copy">
			©{{currentYear}} A Zambezi Agency
		</p>
	</div>
</footer>

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';
import { CustomReuseStrategy } from './route-reuse.strategy';
import { HttpClientModule } from '@angular/common/http';
import { GraphQLModule } from './graphql.module';

import { AppRoutingModule } from './app-routing.module';
// import { NgxCursorModule } from './core/lib/ngx-cursor.module';

import { AppComponent } from './app.component';
import { PageNotFoundComponent } from './core/components/page-not-found/page-not-found.component';
import { SiteNavComponent } from './core/components/site-nav/site-nav.component';
import { SiteFooterComponent } from './core/components/site-footer/site-footer.component';
import { NavComponent } from './core/components/nav/nav.component';

import { SafePipe } from './core/_pipes/safe.pipe';
import { CustomCommaPipe } from "./core/_pipes/comma.pipe";
import { ShufflePipe } from './core/_pipes/shuffle.pipe';
import { SlugifyPipe } from './core/_pipes/slugify.pipe';


@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        GraphQLModule,
        // NgxCursorModule,
        HttpClientModule,
        PageNotFoundComponent,
        SiteNavComponent,
        SafePipe,
        CustomCommaPipe,
        ShufflePipe,
        SlugifyPipe,
        SiteFooterComponent,
        NavComponent
    ],
    providers: [{
        provide: RouteReuseStrategy,
        useClass: CustomReuseStrategy
    }],
    bootstrap: [AppComponent]
})
export class AppModule { }
